import React from 'react'

function videos() {
  return (
      <div>
          <h1 className='text-3xl'>Videos</h1>
    </div>
  )
}

export default videos