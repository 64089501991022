import React from "react";

function HplaNews() {
  return (
    <div className="">
      <h1 className="text-3xl">HPLA Representation</h1>
      <hr className="my-4" />
    </div>
  );
}

export default HplaNews;
